///API Call
var myHeaders = new Headers();
// myHeaders.append("X-CRC-ID", "gAAAAABh-C4xlCLXgkLPn2MFgKyuc_2BRyrdIK4RVEQ6jvuidX_-DftAcwVj-JanA6jhN1BmPRKXoqj2DAfXEnPbaYGV8NWBMvFgKlhkHbt2Y1H3ofbopQmmINmbGdHBfR2oErrEY0lU");
myHeaders.append("X-Authorization", "048f1579b8b8f75f609f036ecb26623ddd0f58d4ff9193a14d4284ac4ff0c87b9093ed08947f25ea72cd141b23be5f2b12e10ccf4522c327f8172f76d1554fb6");
myHeaders.append("X-ORIGIN", "826bead8ad2ad9ce955028045788f371");


var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow',
 
};
var hostUrl="https://su-bitspilani.org/su/"
// var hostUrl='https://onetap.bits-sutechteam.org/back/'
export default requestOptions;
export {myHeaders,hostUrl};

