/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

import {FaSignOutAlt} from "react-icons/fa"

// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";

import { Box } from "@material-ui/core";

const AdminNavbar = (props) => {
  let history =useHistory();

  const logout=()=>{
    localStorage.removeItem("CRC_username");
    localStorage.removeItem("CRC_password");
    localStorage.removeItem("X_CRC_ID")
    localStorage.removeItem("CRC_displayName")
    localStorage.removeItem("CRC_pfp")
    history.push('/logout');
  }

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Box
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            
          >
            {props.brandText}
          </Box>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span
                  
                  className="avatar avatar-sm rounded-circle">
                    <img
                    style={{width:34,height:34}}
                      alt="..."
                      src={
                        "https://su-bitspilani.org"+localStorage.getItem("CRC_pfp")
                      }
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                     { localStorage.getItem("CRC_displayName") }
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem onClick={logout} >
                  <FaSignOutAlt />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
