/*!

=========================================================
* Argon Dashboard React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Tables from "views/examples/Tables.js";
import TripData from "views/examples/TripData.js";
// import Feedback from "views/examples/Feedback.js";
import Packages from "views/examples/Packages";
import Cabtype from "views/examples/Cabtype";
// import Icons from "views/examples/Icons.js";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Tables,
    layout: "/admin",
  },
  {
    path: "/trip-data",
    name: "Trip Data",
    icon: "ni ni-planet text-blue",
    component: TripData,
    layout: "/admin",
    invisible:true,
  },
  // // {
  // //   path: "/trip-details/:name",
  // //   name: "Trip Details",
  // //   icon: "ni ni-planet text-blue",
  // //   component: TripData,
  // //   layout: "/admin",
  // //   invisible:true,
  // // }
  // ,
  // {
  //   path: "/feedback",
  //   name: "Feedback" ,
  //   icon: "ni ni-collection text-blue",
  //   component: Feedback,
  //   layout: "/admin"
  // },
  {
    path: "/Packages",
    name: "Packages" ,
    icon: "ni ni-collection text-blue",
    component: Packages,
    layout: "/admin"
  },
  {
    path: "/Cabtype",
    name: "Cab-Types" ,
    icon: "ni ni-collection text-blue",
    component: Cabtype,
    layout: "/admin"
  }

];
export default routes;
