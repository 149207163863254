// /*!

// =========================================================
// * Argon Dashboard React - v1.2.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/argon-dashboard-react
// * Copyright 2021 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

// * Coded by Creative Tim

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// */
import React, { forwardRef, useState } from "react";
// import { Search } from "@material-ui/icons"
import MaterialTable from 'material-table';
import { useHistory } from "react-router-dom"
import {
  Card,
  // Table,
  Container,
  Row,
  Button
} from "reactstrap";
// core components
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import GetAppIcon from '@material-ui/icons/GetApp';

import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Input from '@material-ui/core/Input';

import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Select, MenuItem, FormControl, InputLabel, Typography } from "@material-ui/core";
import NoDatafound from '../../assets/img/NotFound/NotFound.png'
import { hostUrl } from "APIDetails/api";
// Import api  ;
// import requestOptions from "APIDetails/api";





const fields = [
  { field: 'booker', title: 'Bookers' },
  { field: 'booker_id', title: 'BITS ID' },
  { field: 'unique_hash', title: 'Trip ID' },
  { field: 'trip_cost', title: 'Cost of Trip' },
  { field: 'time_of_journey', title: 'Date of Journey' },
  { field: 'status', title: 'Status' },
];




const Tables = () => {
  var myHeaders = new Headers();
  myHeaders.append("X-CRC-ID", localStorage.getItem("X_CRC_ID"));
  myHeaders.append("X-Authorization", "048f1579b8b8f75f609f036ecb26623ddd0f58d4ff9193a14d4284ac4ff0c87b9093ed08947f25ea72cd141b23be5f2b12e10ccf4522c327f8172f76d1554fb6");
  myHeaders.append("X-ORIGIN", "826bead8ad2ad9ce955028045788f371");


  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  var searchstring = '';
  if(!sessionStorage.getItem('pageNumber'))
  {
    sessionStorage.setItem('pageNumber',1);
    sessionStorage.setItem('status','confirmed');
    sessionStorage.setItem('searchParam',searchstring);
  }
  
  var rows = [];
  const [tableData, setTableData] = React.useState({});
  const [page, setPage] = React.useState(parseInt(sessionStorage.getItem('pageNumber')));
  const [loading, setLoading] = React.useState(true);

  const [searchParam, setSearchParam] = React.useState(sessionStorage.getItem('searchParam')); 
  const [status, setStatus] = React.useState(sessionStorage.getItem('status'));

  const url = `${hostUrl}cabservice/crc_dashboard/home/lower?search=${searchParam}&status=${status}&page=${page}&size=10`
  React.useEffect(() => {

    const loadData = async ()=>{
      await fetch(url, requestOptions)
      .then(response => response.text())
      .then(result => {
        setTableData(JSON.parse(result).lower_dashboard);
        setLoading(true);
      },
      )
      .catch(error => console.log('error', error));
    }
    const timer = setTimeout(() => {
      loadData();
    }, 50);
  }, [url]);


  const handleChange = (event) => {
    setStatus(event.target.value);
    setPage(1);
    sessionStorage.setItem('status',event.target.value);
    sessionStorage.setItem('pageNumber',1);
  };


  const nextPageHandler = () => {
    if(loading)
    {
      // setTableData({});
      setLoading(false);
      sessionStorage.setItem('pageNumber',page+1);
      sessionStorage.setItem('searchParam',searchParam);
      setPage(page + 1);
    }
  }


  const previousePageHanlder = () => {
    if(loading)
    {
      setLoading(false);
      sessionStorage.setItem('pageNumber',page-1);
      sessionStorage.setItem('searchParam',searchParam);
      setPage(page - 1);
    }
  }
  // if(tableData.count) setToggle(true);
  // console.log("the table data is =>");
  // console.log(tableData);

  if (tableData.count) {
    for (let i = 0; i < tableData.results.length; i++) {
      if (rows.length !== tableData.results.length) {
        var tempTime = tableData.results[i].time_of_journey;
        var temp2time = new Date(tempTime.slice(0,10)+" "+tempTime.slice(11,19)+" UTC");
        if(tempTime[10]=='T')   tableData.results[i].time_of_journey =  temp2time.toString().slice(4,21);
        // if(tempTime[10]=='T') tableData.results[i].time_of_journey = tempTime.slice(8,10)+tempTime.slice(4,8)+tempTime.slice(0,4)+" "+tempTime.slice(11,16)+" "+tempTime.slice(19);
        // tableData.results[i].time_of_journey = tableData.results[i].time_of_journey.slice(0, 10)+" "+tableData.results[i].time_of_journey.slice(11,16)+"   "+tableData.results[i].time_of_journey.slice(19);
        rows.push(tableData.results[i]);
        if (rows[i].trip_cost === 0) {
          rows[i].trip_cost = "TBD"
        }
      }
    }
  }


//EXPORT

  var exportRequestOptions = {
    method: 'GET',
  };

  const [exportOn, setExport] = React.useState(false);
  
  React.useEffect(() => {

    const loadData = async ()=>{
      await fetch(`${hostUrl}cabservice/gen_excel/`, exportRequestOptions)
      .then(response => response.text())
      .then(result => {
        window.open(hostUrl+JSON.parse(result).path,"_self");
        setExport(false);
      },
      )
      .catch(error => console.log('error', error));
    }
    const timer = setTimeout(() => {
      if(exportOn===true)
      {
        loadData();
        // console.log("Exporting2.0");
      }  
      // else
      // {
      //   console.log("Not Exporting2.0");
      // }
    }, 500);
  }, [exportOn]);

  const exportHanlder = () => {
    if(exportOn===false)
    {
      setExport(true);
      // console.log("Exporting1.0");
    }
    // else
    // {
    //   console.log("Not pressed Exporting1.0")
    // }
  }

//


  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    // FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    // LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    // NextPage: forwardRef((props, ref) => <ChevronRight  {...props} ref={ref} />),
    // PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  //  const [filtering, setFiltering] = useState(false);
  const history = useHistory();
  return (
    <>

      {/* <Header /> */}
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <MaterialTable

                title=""
                columns={fields}
                data={rows}
                icons={tableIcons}
                options={{
                  // filtering: true,
                  // emptyRowsWhenPaging:false,
                  // exportButton : false,
                  showFirstLastPageButtons: false,
                  exportAllData: true,
                  exportButton: true,
                  headerStyle: {
                    backgroundColor: '#f6f9fc',
                    color: '#8898aa'
                  },
                  filterCellStyle: {
                    height: 20
                  },
                  searchFieldAlignment: 'left',
                  searchFieldStyle: {
                    width: "50vw",
                    align: 'left'
                  },
                  pageSize: 10,
                  pageSizeOptions: [10],
                }}
                localization={{
                  body: {
                    emptyDataSourceMessage: 
                    <>
                    <img src={NoDatafound} alt="" style={{width:"50%",height:"40%"}}/>
                    <Typography style={{
                      marginBottom:15,
                      fontSize:20,
                      textAlign:'center',
                      fontWeight:'bold',
                      color:'#009ac9'
                      }}>
                      No Data Found !
                    </Typography>
                    </>
                  },
                  header: {
                    actions: 'Info'
                  },
                }}
                actions={[
                  // {
                  //   icon: 'filter_alt',
                  //   tooltip: "Filter",
                  //   position: "toolbar",
                  //   onClick: () => {setFiltering(!filtering)}
                  // },
                  {
                    icon: 'assessment',
                    tooltip: "Details",
                    onClick: (event, rowData) => {
                      history.push(`/admin/trip-data`, rowData);
                    }
                  }
                ]}
                components={{
                  Pagination: props => (
                    tableData.count > 0 ?
                      (<div
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          margin: "10px 0px",
                        }}
                      >
                        <FormControl sx={{ m: 2, minWidth: 200 }} style={{marginRight:30}}>
                          <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            defaultValue={status}
                            value={status}
                            label="Status"
                            onChange={handleChange}
                          >
                            <MenuItem value={"confirmed"}>Confirmed/Ongoing</MenuItem>
                            <MenuItem value={"pending"}>Pending</MenuItem>
                            <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                            <MenuItem value={"completed"}>Completed</MenuItem>
                          </Select>
                        </FormControl>
                        <Button
                          disabled={page === 1}
                          onClick={previousePageHanlder}
                          style={{
                            width: 20,
                            height: 20,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <ChevronLeft />
                        </Button>
                        <div
                          style={{
                            fontFamily: 'monospace',
                            marginRight: 8
                          }}
                        >
                          {page}
                        </div>
                        <Button
                          disabled={tableData.next===null}
                          onClick={nextPageHandler}
                          style={{
                            width: 20,
                            height: 20,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <ChevronRight />
                        </Button>
                      </div>)
                      : <>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: "10px 0px",
                          }}
                        >
                          <FormControl sx={{ m: 2, minWidth: 200 }}>
                            <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              defaultValue={status}
                              value={status}
                              label="Status"
                              onChange={handleChange}
                            >
                              <MenuItem value={"confirmed"}>Confirmed/Ongoing</MenuItem>
                              <MenuItem value={"pending"}>Pending</MenuItem>
                              <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                              <MenuItem value={"completed"}>Completed</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </>
                  ),
                  Toolbar: props => (
                    <>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'right',
                          margin: "10px 0px",
                        }}
                      >
                        <SearchIcon/>
                        <Input onChange={(event)=>{setPage(1);setSearchParam(event.target.value);sessionStorage.setItem('searchParam',event.target.value);}} value={searchParam} autoFocus={true}/>
                          <Button
                            disabled={page === 0}
                            onClick={exportHanlder}
                            style={{
                              // width: 5,
                              // height: 40,
                              display: 'flex',
                              justifyContent: 'centre',
                              alignItems: 'start',
                              marginRight: 20,
                              marginLeft: 20,
                            }}
                          >
                            <GetAppIcon/>
                          </Button>
                      </div>
                    </>
                    ),
                }}
              />

            </Card>
          </div>
        </Row>
      </Container>
    </>
  );


};

export default Tables;