import React from 'react';

import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Col,
    
} from "reactstrap";

import { useHistory } from 'react-router';


import { hostUrl } from 'APIDetails/api';

var CryptoJS = require("crypto-js");


function Login() {

    let history = useHistory();
      var flag=0;
    if(localStorage.getItem("CRC_username") && localStorage.getItem("CRC_password")&& flag==0){
        flag=1;
        // console.log(localStorage.getItem("CRC_username"))
        // console.log(localStorage.getItem("CRC_password"))
        var bytes = CryptoJS.AES.decrypt(localStorage.getItem("CRC_username"), 'my-username');
        var decryptedUsername = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        var bytes2 = CryptoJS.AES.decrypt(localStorage.getItem("CRC_password"), 'my-password');
        var decryptedPassword = JSON.parse(bytes2.toString(CryptoJS.enc.Utf8));
        var formdata = new FormData();
        formdata.append("username", decryptedUsername);  //crc_member
        formdata.append("password", decryptedPassword); //crc@bitstest

        var requestOptions2 = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(hostUrl+"cabservice/crc_dashboard/login/", requestOptions2)
            .then(response => response.text())
            .then(result => {
                // console.log(JSON.parse(result));
                JSON.parse(result).status === 1 ?
                 history.push('/admin'):<></>
                 
            })
            .catch(error => console.log('error', error));
        
    }


   

    const [isAllowed, setIsAllowed] = React.useState(false);
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [warning, setWarning] = React.useState('none');



    const checkPassword = (e) => {
        setPassword(e.target.value);

    }

    const checkUsername = (e) => {
        setUsername(e.target.value);
        
    }

    const handleKeypress = (e)=> {
        if (e.key === "Enter") {
            handleAuth();
        }
    };
    

    function checkUserFromDb() {
        if(username&&password){
        var formdata = new FormData();
        formdata.append("username", username);  //crc_member
        formdata.append("password", password); //crc@bitstest

        var requestOptions2 = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(hostUrl+"cabservice/crc_dashboard/login/", requestOptions2)
            .then(response => response.text())
            .then(result => {
                // console.log(JSON.parse(result))
                localStorage.setItem("X_CRC_ID",Object.values(JSON.parse(result))[1])
                localStorage.setItem("CRC_displayName",JSON.parse(result).username)
                localStorage.setItem("CRC_pfp",JSON.parse(result).image)
                JSON.parse(result).status === 1 ? setIsAllowed(true) : setWarning('flex')
            })
            .catch(error => console.log('error', error));
        }
    }
    
    if (isAllowed) {
        localStorage.setItem("CRC_username",CryptoJS.AES.encrypt(JSON.stringify(username), 'my-username').toString());
        localStorage.setItem("CRC_password",CryptoJS.AES.encrypt(JSON.stringify(password), 'my-password').toString());
        history.push('/admin');
    }
    const handleAuth = () => {
        checkUserFromDb();
    }

    // checkUserFromDb();

    const customStyle = {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: '100vh',
        background: '#dbe9ff',
        color: 'black',
        flexDirection: 'column'
    }

    const warningStyle = {
        display: warning,
        color: 'red',
        justifyContent: 'center',
        alignItems: 'center'
    }

    const customFooter={
        background: '#dbe9ff',
    }

    return (
        <div style={customStyle} onKeyPress={handleKeypress}>
            <Col lg="5" md="7">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            <medium>Sign In With Credentials</medium>
                            <hr />

                            <medium style={warningStyle}>Incorrect Login Credentials</medium>
                        </div>
                        <Form role="form">
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-email-83" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Username"
                                        type="name"
                                        autoComplete="new-email"
                                        onChange={checkUsername}
                                        onKeyPress={handleKeypress}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Password"
                                        type="password"
                                        autoComplete="new-password"
                                        onChange={checkPassword}
                                        onKeyPress={handleKeypress}
                                    />
                                </InputGroup>
                            </FormGroup>

                            <div className="text-center">
                                <Button className="my-4" color="primary" type="button" onClick={handleAuth}>
                                    Sign in
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>

            </Col>

            <footer className="footer" style={customFooter}>

                <div className="copyright text-center text-xl-left text-muted">
                    © {new Date().getFullYear()}{" "}
                    <a
                        className="font-weight-bold ml-1"
                        href="https://bits-sutechteam.org/"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        Students' Union Technical Team, BITS Pilani
                    </a>
                </div>

            </footer>

        </div>
    );
};

export default Login;
