// /*!

// =========================================================
// * Argon Dashboard React - v1.2.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/argon-dashboard-react
// * Copyright 2021 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

// * Coded by Creative Tim

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// */
import React from "react";
import { useLocation } from "react-router";
// reactstrap components
import { Box } from '@material-ui/core';
import { hostUrl } from "APIDetails/api";
// import { Search } from "@material-ui/icons"

// import { useRouteMatch } from "react-router-dom";
import {StyleRoot} from "radium";

import {

  Card,
  CardFooter,
  CardBody,

  // Table,
  Container,
  Row,

} from "reactstrap";
// core components

// import requestOptions from "APIDetails/api";
// import { keys } from "@material-ui/core/styles/createBreakpoints";






const DataList = () => {


  var myHeaders = new Headers();
  myHeaders.append("X-CRC-ID", localStorage.getItem("X_CRC_ID"));
  myHeaders.append("X-Authorization", "048f1579b8b8f75f609f036ecb26623ddd0f58d4ff9193a14d4284ac4ff0c87b9093ed08947f25ea72cd141b23be5f2b12e10ccf4522c327f8172f76d1554fb6");
  myHeaders.append("X-ORIGIN", "826bead8ad2ad9ce955028045788f371");
  
  
  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
   
  };

  
  const passengerData = [];
  const location = useLocation();
  const data = location.state;
  const [personDetails, setPersonDetails] = React.useState({});
  React.useEffect(() => {
    fetch(hostUrl+"cabservice/crc_dashboard/detail/" + data.static_id, requestOptions)
      .then(response => response.text())
      .then(result => setPersonDetails(JSON.parse(result)[0]))
      .catch(error => console.log('error', error))
  }, [data.static_id])
  // console.log(personDetails);

  if (personDetails.co_passenger) {
    for (let i = 0; i < personDetails.co_passenger.length; i++) {
      passengerData.push(personDetails.co_passenger[i]);
    }
  }
  const list = [];
  if (personDetails.overhead_expense) {
    // var keysobj=Object.keys(personDetails.overhead_expense);
    // var valuesobj=Object.values(personDetails.overhead_expense);
    // console.log(keysobj,valuesobj);
    for (let i = 0; i < personDetails.overhead_expense.length; i++) {
      var obj={};
      obj.name=personDetails.overhead_expense[i].title;
      obj.value=personDetails.overhead_expense[i].cost;
      obj.link= personDetails.overhead_expense[i].image;
      list.push(obj);
    }
  }
  if(personDetails.time_of_journey){
    var tempTime = personDetails.time_of_journey;
    var temp2time = new Date(tempTime.slice(0,10)+" "+tempTime.slice(11,19)+" UTC");
    personDetails.time_of_journey =  temp2time.toString().slice(0,21);
    // console.log(tempTime.slice(0,10)+" "+tempTime.slice(11,19)+" UTC")
    // personDetails.time_of_journey = Date(tempTime.slice(8,10)+tempTime.slice(4,8)+tempTime.slice(0,4)+" "+tempTime.slice(11,16)+" "+tempTime.slice(19)).toString().slice(0,21)
  }

  // console.log(personDetails,"<- person details");
  
  const cordStyle={
    fontWeight:'bold',
    fontSize:20,
    margin:'10px 0',
    color:"#32325d",
    '@media (max-width: 1290px)': {
      fontSize:18,
    },
    '@media (max-width: 1000px)': {
      fontSize:15,
    },
    
    '@media (max-width: 760px)': {
      fontSize:12,
    }
  }

  const priceStyle={
    fontSize:40,
    fontWeight:'bold',
    color:"#32325d" ,
    '@media (max-width: 1050px)': {
      fontSize:30,
    } , 
    '@media (max-width: 935px)': {
      fontSize:25,
    } , 
    '@media (max-width: 690px)': {
      fontSize:18,
      margin:"20px 0"
    }
  }

  return (
    <StyleRoot>
      {/* <Header /> */}
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              {/* <CardHeader className="border-0">
                  <h1 className="mb-0">Trip ID: # {personDetails.static_id}</h1>
                </CardHeader> */}
              <CardBody>
                <Card className="smoke">
                  <CardBody display="flex" flexDirection="row" className="cardbody">
                    <Box className="col1">
                      <div className="source">
                        <h4 className="mb-0">Start Location</h4>
                        <div style={cordStyle}>{personDetails.starting_location}</div>
                      </div>
                      {/* <div className="distance">
                      <h4 className="mb-0">Distance</h4>
                      <h4 className="mb-5 name__val">4000 KM</h4>
                      </div> */}
                      <div className="status">
                        <h4 className="mb-0">Status</h4>
                        <h5 className="mb-3 name__val">{personDetails.status}</h5>
                      </div>
                    </Box>
                    <Box className="col2">
                      <div className="dest">
                        <h4 className="mb-0">End Location</h4>
                        <div style={cordStyle}>{personDetails.drop_location}</div>
                      </div>
                      <div className="price">
                        <h4 className="mb-0">Car</h4>
                        <h4 className="mb-3 name__val">{personDetails.car}</h4>
                      </div>
                    </Box>
                    <Box className="col4">
                      <div className="price">
                        <h3 className="mb-0">Price</h3>
                        <div style={priceStyle}>RS. {personDetails.trip_cost===0?"TBD":personDetails.trip_cost}/-</div>
                      </div>
                    </Box>

                    <Box className="col2">
                      <div className="price">
                          <div className="mb-3 name__val">Trip Id: {personDetails.unique_hash}</div>
                      </div>
                    </Box>

                    <Box className="col4" style={{marginTop:18}}>
                      <div className="price">
                          <div className="mb-1 name__val">Time of Journey: {personDetails.time_of_journey}</div>
                      </div>
                    </Box>
                  </CardBody>

                  
                </Card>

                {(personDetails.odometer_start || personDetails.odometer_send) && (personDetails.odometer_start.length || personDetails.odometer_end.length)?
                <Card className="smoke">
                  <CardBody display="flex" flexDirection="row" className="cardbody">
                    <Box className="col1plus2">
                      <h1 className="mb-3">Odometer Pictures</h1>
                      <Box style={{display:"flex",flexDirection:"row"}}>
                      {personDetails.odometer_start?
                        <Box >
                        <div className="expenses" style={{float:"left",width:"50%"}}>
                          <h2 className="mb-3">Odometer Start</h2>
                  
                        </div>
                        {/* {item.link?<div style={{ backgroundImage:`url(${item.link})`, width:"30%",height:"60%",float:"left",backgroundColor:"blue",marginLeft:"40%",padding:"10px",backgroundSize:"100% 100%",backgroundRepeat:"no-repeat"}}> */}
{/*                         
                       </div>:<></>} */}
                       
                       <span style={{display:"inline-block",float:"left"}} >
                       <img src={hostUrl+personDetails.odometer_start} style={{width:"90%"}} alt="overhead expense"></img>
                       </span>:<></>
                        </Box>
                      :<></>}
                      {personDetails.odometer_end?
                        <Box>
                        <div className="expenses" style={{float:"left",width:"50%"}}>
                          <h2 className="mb-3">Odometer End</h2>
                  
                        </div>
                        {/* {item.link?<div style={{ backgroundImage:`url(${item.link})`, width:"30%",height:"60%",float:"left",backgroundColor:"blue",marginLeft:"40%",padding:"10px",backgroundSize:"100% 100%",backgroundRepeat:"no-repeat"}}> */}
{/*                         
                       </div>:<></>} */}
                       
                       <span style={{display:"inline-block",float:"left"}} >
                       <img src={hostUrl+personDetails.odometer_end} style={{width:"90%"}} alt="overhead expense"></img>
                       </span>:<></>
                        </Box>
                      :<></>}
                      </Box>
                    </Box>
                      
                      

                  </CardBody>
                </Card>
                :""}

               {list.length?
                <Card className="smoke">
                  <CardBody display="flex" flexDirection="row" className="cardbody">
                    <Box className="col1plus2">
                      <h1 className="mb-3">Overhead Expenses</h1>
                      {list.map((item, index) => (
                        <>
                        <div className="expenses" style={{float:"left",width:"30%"}}>
                          <h2 className="mb-3">{index + 1}. {item.name}</h2>
                          <h4 className="mb-2">Amount: Rs. {item.value}/-</h4>
                  
                        </div>
                        {/* {item.link?<div style={{ backgroundImage:`url(${item.link})`, width:"30%",height:"60%",float:"left",backgroundColor:"blue",marginLeft:"40%",padding:"10px",backgroundSize:"100% 100%",backgroundRepeat:"no-repeat"}}> */}
{/*                         
                       </div>:<></>} */}
                       {item.link?
                       <span style={{display:"inline-block",float:"left"}} >
                       <img src={item.link} style={{width:"40%"}} alt="overhead expense"></img>
                       </span>:<></>}
                        </>
                      ))}
                    </Box>
                      
                      

                  </CardBody>
                </Card>
                :""}
                <Card className="smoke">
                  <CardBody display="flex" flexDirection="row">

                    <div className="booker-details">
                      <h1 className="mb-4">Booker Details</h1>

                      <div className="expenses mb-3">
                        <h4 className="mb-2" style={{fontWeight:'bold'}}>Name: {personDetails.booker}</h4>
                        <h4 className="mb-2">BITS ID: {personDetails.booker_id}</h4>
                        <h4 className="mb-2">Phone Number: {personDetails.booker_mob}</h4>
                      </div>

                    </div>
                    
                    {passengerData.length > 0 ? (<div className="co-passengerdetails">
                      <h1 className="mb-4">Co-passenger Details</h1>
                      <div className="cardbody2">
                        {passengerData.map(per => (

                          <div className="cops mb-3">
                            <h4 className="mb-2">Name: {per.name}</h4>
                            <h4 className="mb-2">BITS ID: {Object.values(per)[1]}</h4>
                            <h4 className="mb-2">Status: {per.status}</h4>
                            <h4 className="mb-2">Phone Number: {per.mobile_number}</h4>
                          </div>)

                        )}
                      </div>
                    </div>
                    ) : <></>}

                  </CardBody>
                </Card>

                {personDetails.feedback ?
                  <Card className="smoke">
                    <CardBody display="flex" flexDirection="row" className="cardbody">
                      <Box className="col1plus2">
                        <h1 className="mb-3">Feedback</h1>

                        <div className="expenses">
                          <h2 className="mb-3">{personDetails.feedback}</h2>
                        </div>

                      </Box>
                    </CardBody>
                  </Card>
                  : <></>}

              </CardBody>
              <CardFooter className="py-4">
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </StyleRoot>
  );


};

export default DataList
