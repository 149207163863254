// /*!

// =========================================================
// * Argon Dashboard React - v1.2.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/argon-dashboard-react
// * Copyright 2021 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

// * Coded by Creative Tim

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// */
import React, { forwardRef } from "react";
// import { Search } from "@material-ui/icons"
import MaterialTable from "@material-table/core";
import { hostUrl } from "APIDetails/api";
import {
  Card,
  // Table,
  Container,
  Row,
} from "reactstrap";
// core components
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';

import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

// Import api  ;
// import requestOptions from "APIDetails/api";
// import { myHeaders } from "APIDetails/api";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),

  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


const fields = [
  { field: 'name', title: 'NAME', editable: "onAdd", validate: rowData => Boolean(rowData.name) },
  { field: 'passenger_seats', title: 'NO. OF PASSENGERS', editable: "onAdd", type: 'numeric', align: 'centre', validate: rowData => Boolean(rowData.passenger_seats) && Boolean(rowData.passenger_seats > 0), },
  { field: 'rate_per_km', title: 'RATE/KM', type: 'numeric', align: 'centre', validate: rowData => Boolean(rowData.rate_per_km) && Boolean(rowData.rate_per_km > 0) },
  { field: 'is_hidden', title: 'hidden', hidden: 'true' },

];




const Cabtype = () => {

  var myHeaders = new Headers();
  myHeaders.append("X-CRC-ID", localStorage.getItem("X_CRC_ID"));
  myHeaders.append("X-Authorization", "048f1579b8b8f75f609f036ecb26623ddd0f58d4ff9193a14d4284ac4ff0c87b9093ed08947f25ea72cd141b23be5f2b12e10ccf4522c327f8172f76d1554fb6");
  myHeaders.append("X-ORIGIN", "826bead8ad2ad9ce955028045788f371");


  let requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',

  };

  var temp = [];

  var dataExists=0;

  const [data, setData] = React.useState();
  React.useEffect(() => {
    fetch(hostUrl+"cabservice/crc_dashboard/cabtype/", requestOptions)
      .then(response => response.text())
      .then(result => {
        // console.log(result);
        for (let i = 0; i < JSON.parse(result).length; i++) {
          temp.push(JSON.parse(result)[i]);
        }
        setData(temp);
      })

      .catch(error => console.log('error', error));
  }, []);

  if(data) dataExists = 1;

  // if (data) {
    return (
      <>
        {/* <Header /> */}
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <MaterialTable

                  editable={{

                    // onBulkUpdate: changes =>
                    //   new Promise((resolve, reject) => {
                    //     setTimeout(() => {
                    //       /* setData([...data, newData]); */

                    //       resolve();
                    //     }, 1000);
                    //   }),

                    onRowAdd: newData =>
                      new Promise((resolve, reject) => {

                       
                        var formdata = new FormData();
                        formdata.append("name", newData.name);
                        formdata.append("passenger_seats", newData.passenger_seats);
                        formdata.append("rate_per_km", newData.rate_per_km);


                        let requestOptions2 = {
                          method: 'POST',
                          headers: myHeaders,
                          body: formdata,
                          redirect: 'follow'
                        };

                        fetch(hostUrl+"cabservice/crc_dashboard/cabtype/", requestOptions2)
                          .then(response => response.text())
                          .then(result => {
                            // console.log(JSON.parse(result))
                            if (JSON.parse(result).name) {
                              setData([...data, newData]);
                              resolve();
                            }
                            else {
                              JSON.parse(result).message ? alert(JSON.parse(result).message) : <></>
                              reject()
                            }
                            //  if(JSON.parse(result).name=="Cab Type with this Name already exists."){
                            //    details.is_hidden=false;
                            //    handleRowDelete(details);
                            //  }
                          })
                          .catch(error => {
                            reject()
                            // alert(JSON.parse(error).message)

                          })



                        // handleRowAdd(newData);


                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        // setTimeout(() => {
                        const dataUpdate = [...data];
                        const index = oldData.tableData.id;
                        dataUpdate[index] = newData;

                        var formdata = new FormData();
                        formdata.append("rate_per_km", newData.rate_per_km);


                        let requestOptions2 = {
                          method: 'PATCH',
                          headers: myHeaders,
                          body: formdata,
                          redirect: 'follow'
                        };

                        fetch(hostUrl+"cabservice/crc_dashboard/cabtype/" + newData.name + "/", requestOptions2)
                          .then(response => response.text())
                          .then(result => {
                            // console.log(result)
                            if (JSON.parse(result).status !== 0) {
                              setData([...dataUpdate]);
                              resolve();
                            }
                            else {
                              reject()
                            }
                          }
                          )
                          .catch(error => reject());

                        // handleRowUpdate(newData);

                        // }, 1000);
                      }),
                    onRowDelete: oldData =>
                      new Promise((resolve, reject) => {
                      // setTimeout(() => {
                        const dataDelete = [...data];
                        const index = oldData.tableData.id;
                        dataDelete[index].is_hidden = true;

                        var formdata = new FormData();
                        formdata.append("is_hidden", dataDelete[index].is_hidden);


                        let requestOptions2 = {
                          method: 'PATCH',
                          headers: myHeaders,
                          body: formdata,
                          redirect: 'follow'
                        };

                        fetch(hostUrl+"cabservice/crc_dashboard/cabtype/" + dataDelete[index].name + "/", requestOptions2)
                          .then(response => response.text())
                          .then(result =>
                            {
                            // console.log(result)
                            if(JSON.parse(result).is_hidden===true){
                              dataDelete.splice(index, 1);

                        setData([...dataDelete]);

                        resolve();
                            }
                            else reject()
                            }
                            )
                          .catch(error => reject());

                        // handleRowDelete(dataDelete[index]);
                        
                        // }, 1000);
                      })
                  }}
                  title=""
                  columns={fields}
                  data={data}
                  icons={tableIcons}
                  options={{
                    // filtering: true,
                    actionsColumnIndex: -1,
                    exportAllData: true,
                    exportButton: true,
                    headerStyle: {
                      backgroundColor: '#f6f9fc',
                      color: '#8898aa'
                    },
                    filterCellStyle: {
                      height: 20
                    },
                    searchFieldAlignment: 'left',
                    searchFieldStyle: {
                      width: "50vw",
                      align: 'left'
                    }
                  }}
                  localization={{
                    body:{ emptyDataSourceMessage: dataExists===0?<div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span> 
                  </div>:<h1>You have removed all data</h1>},
                    header: {
                      actions: 'EDIT'
                    }
                  }}
                  actions={[
                    // {
                    //   icon: 'filter_alt',
                    //   tooltip: "Filter",
                    //   position: "toolbar",
                    //   onClick: () => {setFiltering(!filtering)}
                    // },
                    // {
                    //   icon: 'assessment',
                    //   tooltip: "Details",
                    //   onClick: (event, rowData) => {
                    //     history.push(`/admin/trip-data`, rowData);
                    //   }
                    // }
                  ]}
                />

              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  // }
  // else return <></>

};

export default Cabtype;